import React from "react";

export const AlсoholOutlined: React.FC = () => {
  return (
    <span role="img" className="anticon">
      <svg viewBox="0 0 24 24" focusable="false" width="1em" height="1em" stroke="currentColor" aria-hidden="true">
        <path
          d="M12 13V21M12 13C15.3137 13 18 10.3137 18 7V3H6V7C6 10.3137 8.68629 13 12 13ZM8 21H16"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    </span>
  );
};
