export const exam = (id: number): string => ['exams', id].join('/');
export const inspection = (id: number): string => ['inspections', id].join('/');
export const contract = (id: number): string => ['contracts', id].join('/');
export const venueJournal = (contractId: number, venueId: number): string => [
  contract(contractId),
  'venues',
  venueId,
  'exams',
].join('/');
export const subdivisionJournal = (contractId: number, subdivisionId: number): string => [
  contract(contractId),
  'subdivisions',
  subdivisionId,
  'exams',
].join('/');
export const subdivisionEmployees = (contractId: number, subdivisionId: number): string => [
  contract(contractId),
  'subdivisions',
  subdivisionId,
  'employees',
].join('/');
export const subdivisionRiskGroup = (contractId: number, subdivisionId: number): string => [
  contract(contractId),
  'subdivisions',
  subdivisionId,
  'risk-group',
].join('/');
