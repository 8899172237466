import _ from 'lodash';
import moment from 'moment';
import { normalize } from 'normalizr';
import { handleActions } from 'redux-actions';
import Immutable from 'seamless-immutable';

import * as actions from '../actions';
import * as schemas from './schemas';

const defaultState = Immutable({
  entities: Immutable({}),
  allowanceChangingStatus: '',
  fetchingExamsStatus: '',
});

export const exams = handleActions({
  [actions.fetchExamsSuccess.toString()](state, { payload }: any) {
    const {
      entities,
      allowanceChangingStatus,
      fetchingExamsStatus,
    } = state;

    const normalized = normalize(payload.exams, schemas.examListSchema);
    return state.replace({
      entities: {
        exams: _.omitBy(
          entities.exams,
          (exam) => exam.status === 'signed' && moment().diff(exam.updatedAt, 'days') > 1,
        ),
      },
      allowanceChangingStatus,
      fetchingExamsStatus,
    }, { deep: true }).merge({
      entities: _.pick(normalized.entities, ['exams', 'examTypes']),
      fetchingExamsStatus: 'successed',
    }, { deep: true });
  },
  [actions.logoutSuccess.toString()]() {
    return defaultState;
  },
  [actions.cleanUp.toString()](state) {
    const {
      entities,
      allowanceChangingStatus,
      fetchingExamsStatus,
    } = state;

    return state.replace({
      entities: {
        exams: _.omitBy(
          entities.exams,
          (exam) => exam.status !== 'signed' && moment().diff(exam.updatedAt, 'minutes') > 5,
        ),
      },
      allowanceChangingStatus,
      fetchingExamsStatus,
    }, { deep: true });
  },
  [actions.addExam.toString()](state, { payload }: any) {
    const { entities } = normalize([payload.exam], schemas.examListSchema);
    return state.merge({
      entities: _.pick(entities, ['exams', 'examTypes']),
      fetchingExamsStatus: 'successed',
    }, { deep: true });
  },
  [actions.updateExam.toString()](state, { payload }: any) {
    const { entities } = normalize([payload.exam], schemas.examListSchema);
    return state.merge({
      entities: _.pick(entities, ['exams', 'examTypes']),
      fetchingExamsStatus: 'successed',
    }, { deep: true });
  },
  [actions.changeAllowanceSuccess.toString()](state, { payload }: any) {
    const { entities } = normalize([payload.exam], schemas.examListSchema);
    return state.merge({
      entities: _.pick(entities, ['exams', 'examTypes']),
      allowanceChangingStatus: 'successed',
    }, { deep: true });
  },
  [actions.signExamSuccess.toString()](state, { payload }: any) {
    const { entities } = normalize([payload.exam], schemas.examListSchema);
    return state.merge({
      entities: _.pick(entities, ['exams', 'examTypes']),
      fetchingExamsStatus: 'successed',
    }, { deep: true });
  },
  [actions.changeAllowanceRequest.toString()](state) {
    return state.merge({ allowanceChangingStatus: 'requested' });
  },
  [actions.changeAllowanceFailure.toString()](state) {
    return state.merge({ allowanceChangingStatus: 'failed' });
  },
  [actions.fetchExamsRequest.toString()](state) {
    return state.merge({ fetchingExamsStatus: 'requested' });
  },
  [actions.fetchExamsFailure.toString()](state) {
    return state.merge({ fetchingExamsStatus: 'failed' });
  },
}, defaultState);

export default {
  exams,
};
