import _ from 'lodash';
import moment from 'moment';
import React from "react";
import { withRouter } from 'react-router';

import {
  ClockCircleOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  GiftOutlined,
  HeartFilled,
  ManOutlined,
  MedicineBoxOutlined,
  NumberOutlined,
  ReloadOutlined,
  UserOutlined,
  WarningOutlined
} from "@ant-design/icons";

import { Image, Popconfirm, Skeleton, Timeline, Tooltip } from "antd";

import {
  AlсoholOutlined,
  BuildingFilled,
  PulseOutlined,
  ThermometerOutlined,
} from "./icons";

import * as api from '../../api';
import connect from '../../connect';
import { indicatorsColumns } from "./constants";

import {
  AcceptButton,
  Column,
  ColumnItem,
  Columns,
  ColumnTitle,
  DangerText,
  Data,
  DataItem,
  DataText,
  DismissButton,
  EnvironmentTable,
  Grid,
  IndicatorsTable,
  Info,
  InfoButton,
  InfoColumn,
  InfoFlex,
  InfoItem,
  InfoLine,
  Output,
  Photo,
  RejectButton,
  XButton
} from "./styles";


function getTimeString(time: any, offset: number) {
  return [
    moment
      .utc(time)
      .utcOffset(-(offset
        || (Math.abs((new Date()).getTimezoneOffset()) * 60)) / 60)
      .format('DD.MM.YY k:mm:ss.SSS'),
    ' (UTC',
    offset > 0 ? '+' : '-',
    offset / 60,
    ')'
  ].join('')
}

const mapsStateToProps = (state: any, ownProps) => {
  const {
    match: {
      params: {
        id: examId,
      },
    },
  } = ownProps;

  const {
    ExamOnlinePage: {
      fetchingExamPageStatus,
      exam,
    },
    exams: {
      allowanceChangingStatus,
    }
  } = state;

  const props = {
    examId,
    fetchingExamPageStatus,
    exam,
    allowanceChangingStatus
  };
  return props;
};

@connect(mapsStateToProps)
class ExamOnlinePage extends React.Component<any, any> {
  examPageStart!: HTMLDivElement;

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      visible: false,
      current: 1,
      imageLoadStatus: {
        0: 'loading',
        1: 'loading',
        2: 'loading',
        3: 'loading'
      }
    };
  }

  setVisible(visible) {
    console.log({ visible });
    this.setState({ visible });
  }

  setCurrentImage(current) {
    this.setState({ current });
  }

  componentDidMount() {
    const { fetchExamPage, examId } = this.props;
    this.examPageStart.scrollIntoView({ behavior: 'smooth' });
    fetchExamPage({ examId });
  }

  setAllowancesSuccess = (id, allowance) => () => {
    const { changeExamAllowance, history, match } = this.props;
    changeExamAllowance({ id, allowance })
      .then(() => {
        // Проверяем, находимся ли мы на странице /inspections/*
        if (match.path.includes('/inspections/')) {
          history.push('/exams');
        }
      });
  };

  handleImageLoad = (index: number) => {
    this.setState(prevState => ({
      imageLoadStatus: {
        ...prevState.imageLoadStatus,
        [index]: 'loaded'
      }
    }));
  };

  handleImageError = (index: number) => {
    this.setState(prevState => ({
      imageLoadStatus: {
        ...prevState.imageLoadStatus,
        [index]: 'error'
      }
    }));
  };

  render() {
    const {
      exam,
      fetchingExamPageStatus,
      goBack,
      allowanceChangingStatus,
    } = this.props;

    if (fetchingExamPageStatus === '' || fetchingExamPageStatus === 'requested') {
      return (
        <div>
          <InfoItem>
            <XButton onClick={() => goBack()} style={{ marginBottom: 10 }}>Назад</XButton>
          </InfoItem>
          <Grid ref={(el: HTMLDivElement) => this.examPageStart = el}>
            {[1, 2, 3, 4].map((index) => (
              <React.Fragment key={index}>
                <Skeleton.Avatar
                  active
                  shape="square"
                  style={{
                    width: '100%',
                    height: '300px',
                    borderRadius: 0,
                  }}
                />
              </React.Fragment>
            ))}
            <Info>
              <InfoItem>
                <Skeleton active />
              </InfoItem>
            </Info>
            <Info>
              <InfoItem>
                <Skeleton active />
              </InfoItem>
            </Info>
          </Grid >
        </div >
      );
    }

    const { Worker, Contract, }: any = exam;
    const { Subdivisions, RiskGroup } = Worker;
    const [{ Company, EmployeeInfo }] = Subdivisions;

    const currentTime = new Date();
    const currentTimeOffset = -currentTime.getTimezoneOffset();

    const nurse_local_time_offset = _.isNil(exam.nurse_local_time_offset)
      ? currentTimeOffset
      : exam.nurse_local_time_offset;

    const terminal_local_time_offset = _.isNil(exam.terminal_local_time_offset)
      ? currentTimeOffset
      : exam.terminal_local_time_offset;

    const duration = exam.status === 'signed'
      ? Math.floor(moment.duration(moment(exam.updatedAt).diff(exam.createdAt)).asMinutes())
      : Math.floor(moment.duration(moment().diff(exam.createdAt)).asMinutes());

    const status = exam.status || '';

    let statusText = '';
    switch (status) {
      case 'active':
        statusText = 'Проводится';
        break;
      case 'pendingAllowance':
        statusText = 'Ожидание решения о допуске';
        break;
      case 'pendingEmployeeSign':
        statusText = 'Ожидание подписи работника';
        break;
      case 'pendingNurseSign':
        statusText = 'Ожидание подписи медицинского работника';
        break;
      case 'signed':
        statusText = 'Завершен';
        break;
      default:
        break;
    }

    const indicators = [
      { indicator: "САД (мм.рт.ст.)", limits: [exam.sysMin, exam.sysMax].join(' - ') },
      { indicator: "ДАД (мм.рт.ст.)", limits: [exam.diaMin, exam.diaMax].join(' - ') },
      { indicator: "Пульс (мм.рт.ст.)", limits: [exam.pulseMin, exam.pulseMax].join(' - ') },
    ];

    const truncatedText =
      "Тест";
    const fullText =
      "Тест Тест";

    const photos = [
      { src: exam.Worker.control_photo_id === null ? "/avatar.png" : api.userAvatar(Worker.id), alt: "Контрольное фото" },
      { src: api.examPhotoPulse(exam.id), alt: "Фото 2" },
      { src: api.examPhotoTemperature(exam.id), alt: "Фото 3" },
      { src: api.examPhotoAlcohol(exam.id), alt: "Фото 4" },
    ];

    const references = RiskGroup?.References;

    const riskReferences = RiskGroup
      ? {
        SYS: _.find(references, ['IndicatorTypeId', 1]),
        DIA: _.find(references, ['IndicatorTypeId', 2]),
        PULSE: _.find(references, ['IndicatorTypeId', 3]),
        TEMPERATURE: _.find(references, ['IndicatorTypeId', 4]),
        ALCOHOL: _.find(references, ['IndicatorTypeId', 5]),
        AGE: moment().diff(moment(Worker.dateOfBirth).format('L'), 'years') >= 55,
      }
      : {
        SYS: false,
        DIA: false,
        PULSE: false,
        TEMPERATURE: false,
        ALCOHOL: false,
        AGE: moment().diff(Worker.dateOfBirth, 'years') >= 55,
      };

    const riskGroupText: any[] = [];
    if (RiskGroup != null && RiskGroup?.diagnos != null) {
      riskGroupText.push('Диагноз');
    }
    if (riskReferences.SYS || riskReferences.DIA)
      riskGroupText.push('Давление');
    if (riskReferences.PULSE)
      riskGroupText.push('Пульс');
    if (riskReferences.AGE)
      riskGroupText.push('Возраст');

    const isSysNormal = exam.sysValue >= exam.sysMin && exam.sysValue <= exam.sysMax;
    const isDiaNormal = exam.diaValue >= exam.diaMin && exam.diaValue <= exam.diaMax;
    const isPulseNormal = exam.pulseValue >= exam.pulseMin && exam.pulseValue <= exam.pulseMax;
    const isTemperatureNormal = exam.temperatureValue >= exam.temperatureMin
      && exam.temperatureValue <= exam.temperatureMax;
    const isAlcoholNormal = exam.alcoholValue >= exam.alcoholMin
      && exam.alcoholValue <= exam.alcoholMax;
    const isComplaintsNormal = !exam.complaints || exam.complaints.length == 0;

    const { ExamType: { id: examTypeId } } = exam;
    const examType = examTypeId === 1
      ? 'предрейсовый'
      : examTypeId === 2
        ? 'послерейсовый'
        : examTypeId === 3
          ? 'предсменный'
          : examTypeId === 4
            ? 'послесменный'
            : 'в течение рабочего дня (смены)';

    return (
      <div>
        <InfoItem>
          <XButton onClick={() => goBack()} style={{ marginBottom: 10 }}>Назад</XButton>
        </InfoItem>
        <Grid ref={(el: HTMLDivElement) => this.examPageStart = el}>
          {photos.map((photo, index) => (
            <React.Fragment key={index}>
              {this.state.imageLoadStatus[index] === 'loading' && (
                <Skeleton.Avatar
                  active
                  shape="square"
                  style={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 0 // для строго прямоугольной формы
                  }}
                />
              )}
              {this.state.imageLoadStatus[index] === 'error' && (
                <div style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#f0f0f0',
                  color: '#999',
                  border: '1px dashed #d9d9d9'
                }}>
                  <span>Не удалось загрузить изображение</span>
                </div>
              )}
              {this.state.imageLoadStatus[index] === 'loaded' && (
                <Photo
                  style={{ backgroundImage: `url(${photo.src})` }}
                  onClick={() => {
                    this.setCurrentImage(index);
                    this.setVisible(true);
                  }}
                />
              )}
              {index === 0 && (
                <>
                  <XButton>Сбросить контрольное фото</XButton>
                  <Tooltip title={''}>
                    <Output></Output>
                  </Tooltip>
                </>
              )}
            </React.Fragment>
          ))}
          <div style={{ display: 'none' }}>
            <Image.PreviewGroup
              preview={{
                visible: this.state.visible,
                onVisibleChange: vis => this.setVisible(vis),
                current: this.state.current
              }}
            >
              {photos.map((photo, index) => (
                <Image
                  key={index}
                  src={photo.src}
                  onLoad={() => this.handleImageLoad(index)}
                  onError={() => this.handleImageError(index)}
                />
              ))}
            </Image.PreviewGroup>
          </div>
          <Info>
            <InfoItem hidden={riskGroupText.length === 0}>
              <DangerText>{
                "Группа риска: " + riskGroupText.join(', ')
              }</DangerText>
            </InfoItem>
            <InfoLine>
              <InfoItem>
                <ManOutlined />
                <p>{Worker.sex ? 'женский' : 'мужской'}</p>
              </InfoItem>
              <InfoItem>
                <GiftOutlined />
                <p>{[moment(Worker.dateOfBirth).format('L'), ',', moment().diff(Worker.dateOfBirth, 'years'), 'лет'].join(' ')}</p>
              </InfoItem>
            </InfoLine>
            <InfoLine>
              <InfoItem>
                <EnvironmentOutlined />
                <p>{Contract.name}</p>
              </InfoItem>
            </InfoLine>
            <InfoLine>
              <InfoItem>
                <EyeOutlined />
                <p>{examType}</p>
              </InfoItem>
              <InfoItem>
                <NumberOutlined />
                <p>{exam.uuid}</p>
              </InfoItem>
            </InfoLine>
            <InfoLine>
              <InfoItem >
                {
                  <Popconfirm
                    okText="Закрыть"
                    okButtonProps={{ size: 'large' }}
                    showCancel={false}
                    title={
                      <video width="640" controls>
                        <source src={api.examVideo(exam.id)} type="video/mp4" />
                      </video>
                    }
                  >
                    <InfoButton>
                      <span>Проиграть видео осмотра</span>
                    </InfoButton>
                  </Popconfirm>
                }
              </InfoItem>
              <InfoItem>
                <ClockCircleOutlined />
                <p>{duration} мин</p>
              </InfoItem>
              <InfoItem>
                <ReloadOutlined />
                <p>попытка # {exam.attempt_number || 1}</p>
              </InfoItem>
            </InfoLine>
            <InfoLine style={{ alignSelf: "center" }}>
              <InfoItem hidden={Company.attention == null || Company.attention == false}>
                <WarningOutlined style={{ color: "#ab2c1e", fontSize: "32px" }} />
                <DangerText style={{ fontSize: "3hv" }} >{
                  "Особое внимание!"
                }</DangerText>
              </InfoItem>
            </InfoLine>
            <InfoFlex>
              <InfoColumn danger={!isComplaintsNormal}>
                <MedicineBoxOutlined />
                {isComplaintsNormal ? 'Жалоб нет' : 'Есть жалобы'}
              </InfoColumn>
              <InfoColumn danger={!isAlcoholNormal}>
                <AlсoholOutlined />
                {exam.alcoholValue}
              </InfoColumn>
              <InfoColumn danger={!isSysNormal || !isDiaNormal}>
                <HeartFilled />
                {[exam.sysValue, exam.diaValue].join(' / ')}
              </InfoColumn>
              <InfoColumn danger={!isPulseNormal}>
                <PulseOutlined />
                {exam.pulseValue}
              </InfoColumn>
              <InfoColumn danger={!isTemperatureNormal}>
                <ThermometerOutlined />
                {exam.temperatureValue}
              </InfoColumn>
            </InfoFlex>
          </Info>
          <AcceptButton
            hidden={status !== 'pendingAllowance'}
            loading={allowanceChangingStatus === 'requested'}
            onClick={this.setAllowancesSuccess(exam.id, true)}>Допустить</AcceptButton>
          <RejectButton
            hidden={status !== 'pendingAllowance'}
            loading={allowanceChangingStatus === 'requested'}
            onClick={this.setAllowancesSuccess(exam.id, false)} >Отклонить</RejectButton>
          <DismissButton
            hidden={status !== 'pendingAllowance'}
            loading={allowanceChangingStatus === 'requested'}
            onClick={this.setAllowancesSuccess(exam.id, false)} >Не допустить</DismissButton>
        </Grid >
        <Columns>
          <Column>
            <ColumnItem>
              <ColumnTitle>Границы нормы</ColumnTitle>
              <IndicatorsTable
                columns={indicatorsColumns}
                dataSource={indicators.map((item, index) => ({ ...item, key: index }))}
                rowKey="key"
                scroll={{ x: "max-content" }}
                pagination={false}
              />
            </ColumnItem>
            <ColumnItem>
              <ColumnTitle hidden={riskGroupText.length === 0}>Данные о группе риска</ColumnTitle>
              <Data>
                <DataItem>
                  {"Диагноз"}
                  <DataText>
                    {RiskGroup?.diagnos}
                  </DataText>
                </DataItem>
                <DataItem>
                  {"Код МКБ-10"}
                  <DataText>
                    {RiskGroup?.icdCode}
                  </DataText>
                </DataItem>
              </Data>
            </ColumnItem>
            <ColumnItem>
              <ColumnTitle>Данные о работнике # {EmployeeInfo.personnel_number} / {Worker.id}</ColumnTitle>
              <Data>
                <DataItem>
                  <BuildingFilled />
                  <DataText>
                    {Company.legalName}
                  </DataText>
                </DataItem>
                <DataItem>
                  <UserOutlined />
                  <DataText>
                    {[Worker.lastName, Worker.firstName, Worker.patronymic].join(' ')}
                  </DataText>
                </DataItem>
              </Data>
            </ColumnItem>
          </Column>
          <Column>
            <ColumnItem hidden>
              <ColumnTitle>Документы</ColumnTitle>
            </ColumnItem>
            <ColumnItem>
              <ColumnTitle>Параметры окружающей среды</ColumnTitle>
              <Data>
                <DataItem>
                  <EnvironmentTable
                    columns={[
                      {
                        title: "Параметр",
                        dataIndex: "indicator",
                        key: "indicator",
                        render: (value) => <p>{value}</p>,
                      },
                      {
                        title: "Значение",
                        dataIndex: "values",
                        key: "values",
                        render: (value) => <p>{value}</p>,
                      },
                    ]}
                    dataSource={[
                      { indicator: "Влажность (%)", values: exam.ambient_humidity },
                      { indicator: "Освещенность (лк)", values: exam.ambient_light },
                      { indicator: "Температура (°C)", values: exam.ambient_temperature },
                    ]}
                    rowKey="key"
                    scroll={{ x: "max-content" }}
                    pagination={false}
                  />
                </DataItem>
              </Data>
            </ColumnItem>
            <ColumnItem>
              <ColumnTitle>История осмотра</ColumnTitle>
              <Data>
                <DataItem>
                  <Timeline>
                    {
                      exam.time_exam_start && (
                        <Timeline.Item>Начало осмотра работником {
                          getTimeString(exam.time_exam_start, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_complaints_start && (
                        <Timeline.Item>Начало опроса на наличие жалоб {
                          getTimeString(exam.time_complaints_start, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_complaints_end && (
                        <Timeline.Item>Окончание опроса на наличие жалоб {
                          getTimeString(exam.time_complaints_end, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_alcohol_start && (
                        <Timeline.Item>Начало измерения концентрации алкоголя в выдыхаемом воздухе {
                          getTimeString(exam.time_alcohol_start, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_alcohol_end && (
                        <Timeline.Item>Окончание измерения концентрации алкоголя в выдыхаемом воздухе {
                          getTimeString(exam.time_alcohol_end, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }

                    {
                      exam.time_temperature_start && (
                        <Timeline.Item>Начало измерения температуры тела {
                          getTimeString(exam.time_temperature_start, terminal_local_time_offset)}
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_temperature_end && (
                        <Timeline.Item>Окончание измерения температуры тела {
                          getTimeString(exam.time_temperature_end, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_bloodpressure_start && (
                        <Timeline.Item>Начало измерения артериального давления и пульса {
                          getTimeString(exam.time_bloodpressure_start, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_bloodpressure_end && (
                        <Timeline.Item>Окончание измерения артериального давления и пульса {
                          getTimeString(exam.time_bloodpressure_end, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_result_confirm && (
                        <Timeline.Item>Подтверждение результатов осмотра работником {
                          getTimeString(exam.time_result_confirm, terminal_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_exam_uploaded && (
                        <Timeline.Item>Загрузка медицинского осмотра на сервер АИС {
                          getTimeString(exam.time_exam_uploaded, nurse_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_pending_decision_start && (
                        <Timeline.Item>Начало рассмотрения результатов осмотра МР {
                          getTimeString(exam.time_pending_decision_start, nurse_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                    {
                      exam.time_nurse_signing_end && (
                        <Timeline.Item>Вынесение заключения МР {
                          getTimeString(exam.time_nurse_signing_end, nurse_local_time_offset)
                        }
                        </Timeline.Item>
                      )
                    }
                  </Timeline>
                </DataItem>
              </Data>
            </ColumnItem>
          </Column>
        </Columns>
      </div >
    );

  }
}

export default withRouter(ExamOnlinePage);
