import { handleActions } from 'redux-actions';

import * as actions from '../actions';


const defaultState = {
  exam: null,
  fetchingExamPageStatus: '',
};

export const ExamOnlinePage = handleActions({
  [actions.fetchExamOnlinePageSuccess.toString()](state, { payload }: any) {
    return {
      ...state,
      exam: payload.exam,
      fetchingExamPageStatus: 'successed',
    };
  },

  [actions.changeAllowanceSuccess.toString()](state, { payload }: any) {
    return {
      ...state,
      exam: payload.exam,
    };
  },

  [actions.logoutSuccess.toString()]() {
    return defaultState;
  },
  [actions.fetchExamOnlinePageRequest.toString()](state) {
    return {
      ...state,
      fetchingExamPageStatus: 'requested',
    };
  },
  [actions.fetchExamOnlinePageFailure.toString()](state) {
    return {
      ...state,
      fetchingExamPageStatus: 'failed',

    }
  },
}, defaultState);

export default {
  ExamPage: ExamOnlinePage,
};
