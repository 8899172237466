import 'moment/locale/ru.js';

import { ConfigProvider, Skeleton } from 'antd';
import Layout, { Content, Footer } from 'antd/es/layout/layout';
import locale from 'antd/es/locale-provider/ru_RU';
import { ConnectedRouter as Router } from 'connected-react-router';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Provider } from 'react-redux';
import {
  Redirect, Route, RouteProps, Switch
} from 'react-router-dom';

import Header from './components/Header';
import NotFound from './components/NotFound';
import SideMenu from './components/SideMenu';
import connect from './connect';
import EmployeesPage from './containers/EmployeesPage';
import CurrentExamPage from './containers/ExamPage';
import CurrentExamOnlinePage from './containers/ExamOnlinePage';
import JournalPage from './containers/JournalPage';
import LoginByDSPage from './containers/LoginByDSPage';
import LoginPage from './containers/LoginPage';
import OnlineJournalPage from './containers/OnlineJournalPage';
import StatisticPage from './containers/StatisticPage';
import * as selectors from './selectors';

moment.locale("ru");

interface PrivateRouteProps extends RouteProps {
  children: any;
}

const mapsStateToProps = (state: any) => {
  const {
    user,
    contracts,
    employees,
    exams,
  } = state;
  const { fetchingUserDetailsStatus } = user;
  const { employeesFetchingStatus } = employees;
  const { fetchingContractsStatus } = contracts;

  const isLoading = _.some([
    fetchingUserDetailsStatus,
    employeesFetchingStatus,
    fetchingContractsStatus,
  ], (v) => (v.includes('requested') || v.includes('failed')));

  return {
    user,
    contracts: selectors.contracts.contractsSelector(state),
    isLoading,
    fetchingUserDetailsStatus,
    fetchingContractsStatus,
  };
};

@connect(mapsStateToProps) class App extends React.Component<any, any> {
  componentDidMount() {
    const {
      user,
      fetchingUserDetailsStatus,
      fetchUserDetails,
    } = this.props;

    if (!user.id) return;

    if (fetchingUserDetailsStatus === '') {
      fetchUserDetails({ id: user.id });
    }
  }

  render() {
    const { history, store, isLoading } = this.props;

    const { isAuthenticated, Roles } = store.getState().user;
    const isNurse = _.includes(Roles, 2);

    const PrivateRoute = (
      {
        children,
        ...rest
      }: PrivateRouteProps,
    ): React.ReactElement<unknown> => (
      <Route
        {...rest}
        render={({ location }) => (isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        ))}
      />
    );

    return (
      isLoading
        ? <Skeleton />
        : (
          <ConfigProvider
            locale={locale}
          >
            <Provider store={store}>
              <Router history={history}>
                <Layout style={{ minHeight: '100vh' }}>
                  <SideMenu />
                  <Layout>
                    <Header style={{ background: 'white', padding: 0 }} />
                    <Content>
                      <div style={{ background: 'white', padding: '20px' }}>
                        <Switch>
                          <Redirect exact from="/" to={'/statistic'} />
                          <PrivateRoute path="/inspections/:id">
                            <CurrentExamOnlinePage />
                          </PrivateRoute>
                          <PrivateRoute path="/exams/:id">
                            <CurrentExamPage />
                          </PrivateRoute>
                          <PrivateRoute path="/exams">
                            <OnlineJournalPage />
                          </PrivateRoute>
                          <PrivateRoute path="/journal">
                            <JournalPage />
                          </PrivateRoute>
                          <PrivateRoute path="/statistic">
                            <StatisticPage />
                          </PrivateRoute>
                          <PrivateRoute path="/employees">
                            <EmployeesPage />
                          </PrivateRoute>
                          <Route path="/login">
                            <LoginPage />
                          </Route>
                          <Route path="/digital-sign-login">
                            <LoginByDSPage />
                          </Route>
                          <Route component={NotFound} />
                        </Switch>
                      </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Медрегистр ©2019 ООО &ldquo;Санрэс&rdquo;</Footer>
                  </Layout>
                </Layout>
              </Router>
            </Provider>
          </ConfigProvider>

        )
    );
  }
}

export default App;
