import React from "react";
import { ColumnsType } from "antd/es/table";

interface indicatorsRO {
  indicator: string;
  limits: string;
  average: string;
}

export const indicatorsColumns: ColumnsType<indicatorsRO> = [
  {
    title: "Показатель",
    dataIndex: "indicator",
    key: "indicator",
    render: (value) => <p>{value}</p>,
  },
  {
    title: "Границы нормы",
    dataIndex: "limits",
    key: "limits",
    render: (value) => <p>{value}</p>,
  },
];
