import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import certs from './certs';
import { contracts } from './contracts';
import { employees } from './employees';
import { exams } from './exams';
import { ExamOnlinePage } from './examOnlinePage';
import { ui } from './ui';
import { user } from './user';

const rootReducer = (history: History) => combineReducers({
  form: formReducer,
  user,
  exams,
  ExamOnlinePage,
  contracts,
  employees,
  certs,
  ui,
  router: connectRouter(history),
});

export interface State {
  form: any;
  user: any;
  exams: any;
  currentExam: any;
  currentEmployee: any;
  certs: any;
  contracts: any;
  employees: any;
  router: RouterState;
  statistic: any;
}

export default rootReducer;
