import React from "react";

export const BuildingFilled: React.FC = () => {
  return (
    <span role="img" className="anticon">
      <svg
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
        focusable="false"
        width="1em"
        height="1em"
        fill="currentColor"
        stroke="currentColor"
        aria-hidden="true"
      >
        <g>
          <path
            d="M18,10v54h9V53c0-0.553,0.447-1,1-1h8c0.553,0,1,0.447,1,1v11h9V20V4c0-2.211-1.789-4-4-4H22
		c-2.211,0-4,1.789-4,4V10z M34,9c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V9z
		 M34,19c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V19z M34,29c0-0.553,0.447-1,1-1
		h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V29z M34,39c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4
		c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V39z M24,9c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4
		c-0.553,0-1-0.447-1-1V9z M24,19c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V19z
		 M24,29c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V29z M24,39c0-0.553,0.447-1,1-1
		h4c0.553,0,1,0.447,1,1v4c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1V39z"
          />
          <rect x="26" y="30" width="2" height="2" />
          <rect x="26" y="40" width="2" height="2" />
          <rect x="29" y="54" width="6" height="10" />
          <rect x="8" y="50" width="2" height="2" />
          <rect x="26" y="10" width="2" height="2" />
          <rect x="26" y="20" width="2" height="2" />
          <rect x="36" y="20" width="2" height="2" />
          <rect x="36" y="10" width="2" height="2" />
          <rect x="36" y="30" width="2" height="2" />
          <rect x="36" y="40" width="2" height="2" />
          <rect x="8" y="40" width="2" height="2" />
          <path
            d="M16,10H4c-2.211,0-4,1.789-4,4v46c0,2.211,1.789,4,4,4h12V10z M12,53c0,0.553-0.447,1-1,1H7
		c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1V53z M12,43c0,0.553-0.447,1-1,1H7c-0.553,0-1-0.447-1-1v-4
		c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1V43z M12,33c0,0.553-0.447,1-1,1H7c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h4
		c0.553,0,1,0.447,1,1V33z M11,24H7c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1v4
		C12,23.553,11.553,24,11,24z"
          />
          <rect x="54" y="50" width="2" height="2" />
          <rect x="54" y="40" width="2" height="2" />
          <rect x="54" y="30" width="2" height="2" />
          <path
            d="M60,20H48v44h12c2.211,0,4-1.789,4-4V24C64,21.789,62.211,20,60,20z M58,53c0,0.553-0.447,1-1,1h-4
		c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1V53z M58,43c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1v-4
		c0-0.553,0.447-1,1-1h4c0.553,0,1,0.447,1,1V43z M58,33c0,0.553-0.447,1-1,1h-4c-0.553,0-1-0.447-1-1v-4c0-0.553,0.447-1,1-1h4
		c0.553,0,1,0.447,1,1V33z"
          />
          <rect x="8" y="30" width="2" height="2" />
          <rect x="8" y="20" width="2" height="2" />
        </g>
      </svg>
    </span>
  );
};
