import { FileTextOutlined } from "@ant-design/icons";
import { Button, Table, Image } from "antd";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-gap: 1vh 1vw;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: 1fr 5vh 1fr 5vh;
  height: 100vh;
  height: calc(100vh - 30px);
  margin-bottom: 2rem;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2vh;
  gap: 1vh;
  grid-column-end: 4;
  grid-column-start: 2;
  grid-row-start: 1;
  padding-left: 1rem;
`;

export const InfoTitle = styled.h1`
  font-size: 4vh;
`;

export const InfoButton = styled(Button)`
  align-items: center;
  display: flex;
  font-size: 2.2vh;
  height: 4.3vh;
  padding: 0 1rem;

  > .anticon + span {
    margin-inline-start: 1.5vh;
  }
`;

export const InfoLine = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  :nth-child(1) {
    margin-bottom: auto;
  }
`;

export const InfoItem = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
`;

export const InfoFlex = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: auto;
  padding-top: 1vh;
`;

export const InfoColumn = styled.div<{danger?: boolean}>`
  align-items: center;
  color: ${(props: any) => (props.danger ? "#ab2c1e" : "inherit")};
  display: flex;
  flex-direction: column;
  font-size: 2vh;
  gap: 1vh;

  span {
    font-size: 5vh;
  }
`;

export const DangerText = styled.p`
  color: #ab2c1e;
`;

export const Photo = styled.div`
  background-position: center;
  background-size: cover;
  height: 100% override;
  width: 100% !important;
`;

export const Output = styled.p`
  align-self: center;
  font-size: 2vh;
  grid-column-end: 4;
  grid-column-start: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: rgba(255, 0, 0, 0.3);
`;

export const Columns = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const XButton = styled(Button)`
  font-size: 1.2rem;
  font-size: 2vh;
  font-weight: 600;
  height: 5vh;
  margin-bottom: 2rem;
`;

export const ConclusionButton = styled(Button)`
  font-size: 1.8rem;
  font-size: 2.4vh;
  font-weight: 600;
  height: 8vh;
  margin-bottom: 2rem;
`;

export const AcceptButton = styled(ConclusionButton)`
  background: #389e0d !important;

  &.ant-btn {
    :hover {
      border-color: #389e0d !important;
      color: #fff !important;
    },
    :visited {
      border-color: #389e0d !important;
      color: #fff !important;
    },
    :active {
      border-color: #389e0d !important;
      color: #fff !important;
    }
  }
`;


export const DismissButton = styled(ConclusionButton)`
  background:rgb(245, 8, 8) !important;

  &.ant-btn {
    :hover {
      border-color:rgb(170, 32, 32) !important;
      color: #fff !important;
    },
    :visited {
      border-color: #389e0d !important;
      color: #fff !important;
    },
    :active {
      border-color:rgb(247, 57, 57) !important;
      color: #fff !important;
    }
  }
`;

export const RejectButton = styled(ConclusionButton)`
  background:rgb(253, 249, 4) !important;

  &.ant-btn {
    :hover {
      border-color:rgb(235, 214, 25) !important;
      color: #fff !important;
    },
    :visited {
      border-color: #389e0d !important;
      color: #fff !important;
    },
    :active {
      border-color: #389e0d !important;
      color: #fff !important;
    }
  }
`;

export const ColumnItem = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnTitle = styled.p`
  font-size: 1.2rem;
  margin-bottom: 0.8rem;
`;

export const IndicatorsTable = styled(Table)`
  margin-bottom: 0.8rem;
`;

export const EnvironmentTable = styled(Table)`
  margin-bottom: 0.8rem;
`;

export const IndicatorsButton = styled(Button)`
  margin-left: auto;
`;

export const Comments = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  max-height: 10.7rem;
  overflow-y: auto;
`;

export const Comment = styled.div`
  border-radius: 0.3rem;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-right: 1rem;
  padding: 0.62rem 0.62rem;

  :nth-child(2n) {
    background-color: #3b3b3b;
  }
`;

export const CommentText = styled.p`
  line-height: 1.15rem;
`;

export const CommentTime = styled.span`
  white-space: nowrap;
`;

export const CommentsFlex = styled.div`
  display: flex;
  gap: 1rem;
`;

export const AddCommentButton = styled(Button)`
  aspect-ratio: 1;
  height: 100%;
`;

export const Documents = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 11rem;
  overflow-y: auto;
`;

export const Document = styled.div`
  border-radius: 0.3rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2.8rem auto;
  margin-right: 1rem;
  padding: 0.62rem 0.62rem;

  :nth-child(2n) {
    background-color: #3b3b3b;
  }
`;

export const DocumentIcon = styled(FileTextOutlined)`
  font-size: 2.8rem;
  grid-row-end: 3;
  grid-row-start: 1;
`;

export const DocumentTitle = styled.p`
  font-size: 1rem;
  padding-top: 0.4rem;
`;

export const DocumentSubtitle = styled.p`
  color: #b4b4b4;
  padding-bottom: 0.4rem;
`;

export const Data = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DataItem = styled.div`
  padding-left: 2.8rem;
  position: relative;

  span {
    display: block;
    font-size: 1.5rem;
    left: 0;
    position: absolute;
    top: 0.5rem;
  }
`;

export const DataText = styled.p`
  font-size: 1rem;
  line-height: 1.6rem;
`;
