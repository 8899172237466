import { employees } from './../reducers/employees';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';
import _ from 'lodash';
import * as api from '../api';

export async function fetchUserContracts(user: any) {
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: window.localStorage.getItem('token'),
    },
  };
  const { data: { data: contracts } } = await axios.get(api.contracts(), config);
  return contracts;
}


export async function fetchExams({
  contracts,
  startOfDay,
  endOfDay,
  status,
  Worker,
  examTypeId,
}: any) {
  try {
    const config: any = {
      params: {
        startDate: startOfDay,
        endDate: endOfDay,
        ContractId: contracts.map((c) => {
          return c.id;
        }),
        'ExamType[id]': examTypeId,
        Worker,
        status,
      },
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    };

    const { data: { data: exams } } = await axios.get(api.exams(), config);
    return exams;
  } catch (error) {
    console.warn(error);
  }
}

export async function fetchExam({
  examId,
}: any) {
  try {
    const config: any = {
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    };

    const { data: { data: exam } } = await axios.get(api.exam(examId), config);
    return exam;
  } catch (error) {
    console.warn(error);
    throw error;
  }
}

export async function fetchEmployees({
  contracts,
}) {
  try {
    const config: any = {
      headers: {
        Authorization: window.localStorage.getItem('token'),
      },
    };

    const { data: { data: employees } } = await axios.get(api.users(), config);
    return employees;
  } catch (error) {
    console.warn(error);
  }
}

export default { fetchUserContracts };
