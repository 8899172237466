import { createAction } from 'redux-actions';

import * as fetch from '../lib/fetch';

export const fetchExamOnlinePageRequest = createAction('REQUEST_FETCH_EXAM_PAGE');
export const fetchExamOnlinePageSuccess = createAction('SUCCESS_FETCH_EXAM_PAGE');
export const fetchExamOnlinePageFailure = createAction('FAILURE_FETCH_EXAM_PAGE');

export const fetchExamPage = (
  {
    examId,
  },
): any => async (dispatch: any): Promise<any> => {
  dispatch(fetchExamOnlinePageRequest({
    examId,
  }));
  try {
    const exam = await fetch.fetchExam({ examId });
    dispatch(fetchExamOnlinePageSuccess({ exam }));
  } catch (error) {
    dispatch(fetchExamOnlinePageFailure(error?.toString()));
  }
};